<template>
    <div>
        <hospital-profile-component/>
    </div>
</template>

<script>
    import HospitalProfileComponent from "@/components/hospitals-clinics/HospitalProfileComponent";

    export default {
        name: "HospitalProfile",
        title: "Perfil del Hospital | Turismo BC",
        components: {
            HospitalProfileComponent,
        },
    }
</script>

<style></style>
